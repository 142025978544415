<script>
import { Collection } from 'sveltefire'
import { newQuiz, context } from '../../stores.js'
import Header from '../../components/Header.svelte'

import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()

export let user
let music = []
let selected = []
$: {
  selected = music.filter((x) => x.selected)
}

function editTrack() {
  dispatch('goto', {
    page: `/music/edit/${selected[0].id}`,
  })
}

async function deleteMusic() {
  let toDelete = selected
  selected = []
  const deletions = toDelete.map((track) => {
    return track.ref.delete()
  })
  await Promise.all(deletions)
  let deletedIDs = toDelete.map((q) => q.id)
  music = music.filter((q) => !deletedIDs.includes(q.id))
}

function useMusic() {
  $newQuiz.sections[$context.section].rounds[
    $context.round
  ].parts = selected.map((q) => {
    return {
      id: q.ref.id,
      url: q.url,
      artist: q.artist,
      title: q.title,
      year: q.year,
      saved: q.saved,
    }
  })
  dispatch('goto', {
    page: '/quizzes/new',
  })
}

function newMusic() {
  dispatch('goto', {
    page: '/music/new',
  })
}

let pageSize = 10
let query = (ref) => ref.orderBy('date', 'desc').limit(pageSize)

function loadMore(last) {
  query = (ref) =>
    ref.orderBy('date', 'desc').startAt(last['date']).limit(pageSize)
}

function mergeData({ detail: { data } }) {
  if (data) {
    const unique = data.filter(d => !music.some(x => x.id === d.id))
    music = [...music, ...unique]
  }
}
</script>

<Header
  title="Music"
  selected={selected.length}
  required={$context ? $context.num : false}
  edit={true}
  on:edit={editTrack}
  on:use={useMusic}
  on:delete={deleteMusic}
  on:open={newMusic} />

<ul>
  {#each music as t}
    <li>
      <span
        on:click={() => {
          t.selected = !t.selected
        }}
        class="block {t.selected ? 'bg-purple-100 hover:bg-purple-100' : 'bg-white hover:bg-purple-50'}">
        <div class="px-4 py-4 sm:px-6">
          <div class="flex items-center justify-between">
            <div class="text-sm font-medium leading-5 text-gray-800">
              {t.artist}
              -
              {t.title}
              ({t.year})
            </div>
            <div class="flex flex-shrink-0 ml-2">
              {#each t.tags as tag}
                <span
                  class="inline-flex px-2 ml-1 text-xs font-semibold leading-5 text-purple-800 rounded-full
                  {t.selected ? 'bg-gray-50' : 'bg-purple-100'}">
                  {tag}
                </span>
              {/each}
            </div>
          </div>
          <div class="mt-2 sm:flex sm:justify-between">
            <div class="sm:flex">
              <div
                class="flex items-center mr-6 text-sm italic leading-5 text-gray-500 hover:underline">
                <a href={t.url} target="_blank">{t.url}</a>
              </div>
            </div>
            <div
              class="flex items-center mt-2 text-sm leading-5 text-gray-500 sm:mt-0">
              <span />
            </div>
          </div>
        </div>
      </span>
    </li>
  {/each}
</ul>

<Collection
  path={`org/${user.org}/music`}
  once={true}
  query={query}
  on:data={mergeData}
  let:last>
  {#if last}
    <div class="flex justify-center mt-8 mb-5">
      <span class="inline-flex rounded-md shadow-sm ">
        <button
          on:click={() => {
            if (last) loadMore(last)
          }}
          type="button"
          class="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50">
          Load More
        </button>
      </span>
    </div>
  {/if}
  <div slot="loading" class="flex justify-center mt-8 mb-5"><span /></div>
</Collection>
