<script>
import { Doc } from 'sveltefire'
import { live } from './stores.js'
import { routes } from './routes.js'
export let user, full


import router from 'page'

let route = routes[0]

routes.filter(r => full || !r.full ).forEach((r) => {
  makeRoute(r)
})

function makeRoute(r, p = '') {
  let path = `${p}/${r.path}`
  if (r.type != 'path') {
    router(path, (context) => {
      route = r
      r.params = context.params
    })
  }
  if (r.pages) r.pages.forEach((c) => makeRoute(c, path))
}

router('*', () => router.redirect(user.admin ? '/' : '/events'))
router.start()

function forward(event) {
  router(event.detail.page)
}

let eventData, eventRef

function setEventData({ detail: { data } }) {
  if (data) eventData = data
}
function setEventRef({ detail: { ref } }) {
  if (ref) eventRef = ref
}
</script>

<svelte:head>
  <title>
    Quizdex •
    {route.title}
    {route.type == 'live' ? `(${$live})` : ''}
  </title>
</svelte:head>

<!--ROUTE-->

{#if route}
  <svelte:component
    this={route.component}
    user={user}
    on:goto={forward}
    params={route.params}
    eventData={eventData}
    eventRef={eventRef} />
{/if}

{#if $live}
  <Doc
    path={`org/${user.org}/events/${$live}`}
    on:data={setEventData}
    on:ref={setEventRef} />
{/if}
