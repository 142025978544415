<script>
import { Collection } from 'sveltefire'
import { newQuiz, context } from '../../stores.js'
import Header from '../../components/Header.svelte'

import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()

export let user
let pictures = []
let selected = []
$: {
  selected = pictures.filter((x) => x.selected)
}

function editPicture() {
  dispatch('goto', {
    page: `/pictures/edit/${selected[0].id}`,
  })
}

async function deletePictures() {
  let toDelete = selected
  selected = []
  const deletions = toDelete.map((picture) => {
    return picture.ref.delete()
  })
  await Promise.all(deletions)
  let deletedIDs = toDelete.map((p) => p.id)
  pictures = pictures.filter((p) => !deletedIDs.includes(p.id))
}

function useQuestions() {
  $newQuiz.sections[$context.section].rounds[
    $context.round
  ].parts = selected.map((q) => {
    return {
      id: q.ref.id,
      url: q.url,
      prompt: q.prompt,
      answer: q.answer,
      saved: q.saved,
    }
  })
  dispatch('goto', {
    page: '/quizzes/new',
  })
}

function newPictures() {
  dispatch('goto', {
    page: '/pictures/new',
  })
}

let pageSize = 12
let query = (ref) => ref.orderBy('date', 'desc').limit(pageSize)

function loadMore(last) {
  query = (ref) =>
    ref.orderBy('date', 'desc').startAt(last['date']).limit(pageSize)
}

function mergeData({ detail: { data } }) {
  if (data) {
    const unique = data.filter(d => !pictures.some(x => x.id === d.id))
    pictures = [...pictures, ...unique]
  }
}
</script>

<Header
  title="Pictures"
  selected={selected.length}
  required={$context ? $context.num : false}
  edit={true}
  on:edit={editPicture}
  on:use={useQuestions}
  on:delete={deletePictures}
  on:open={newPictures} />
<ul
  class="space-y-12 sm:grid sm:grid-cols-2 sm:col-gap-6 sm:row-gap-12 sm:space-y-0 lg:grid-cols-3 lg:col-gap-8">
  {#each pictures as p}
    <li>
      <div class="space-y-4">
        <div
          class="relative pb-2/3 {p.selected ? 'bg-purple-500 border-purple-200 rounded-lg' : 'border-transparent'}
              border-4">
          <img
            on:click={() => {
              p.selected = !p.selected
            }}
            class="{p.selected ? 'opacity-75' : 'shadow-lg'} absolute object-cover h-full w-full rounded-lg
                hover:opacity-75"
            src={p.url}
            alt={p.answer} />
        </div>

        <div class="space-y-2">
          <div class="space-y-1 text-lg font-medium leading-6">
            <h4>{p.prompt} ({p.answer})</h4>
            <div class="flex pt-2">
              {#each p.tags as tag}
                <span
                  class="inline-flex px-2 mr-1 text-xs font-semibold leading-5 text-purple-800 transition duration-150 ease-in-out bg-purple-100 rounded-full">
                  {tag}
                </span>
              {/each}
            </div>
          </div>
        </div>
      </div>
    </li>
  {/each}
</ul>

<Collection
  path={`org/${user.org}/pictures`}
  once={true}
  query={query}
  on:data={mergeData}
  let:last>
  {#if last}
    <div class="flex justify-center mt-8 mb-5">
      <span class="inline-flex rounded-md shadow-sm ">
        <button
          on:click={() => {
            if (last) loadMore(last)
          }}
          type="button"
          class="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50">
          Load More
        </button>
      </span>
    </div>
  {/if}
  <div slot="loading" class="flex justify-center mt-8 mb-5"><span /></div>
</Collection>
