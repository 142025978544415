<script>
import formatRelative from 'date-fns/formatRelative'
import { enGB } from 'date-fns/locale'
import { Collection } from 'sveltefire'
import { live } from '../../stores.js'
import Header from '../../components/Header.svelte'

import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()

export let user
let events = []
let last

function newEvent() {
  dispatch('goto', {
    page: '/events/new',
  })
}

let pageSize = 60

let query = (ref) => ref.orderBy('date', 'desc').limit(pageSize)
function loadMore(last) {
  query = (ref) =>
    ref.orderBy('date', 'desc').startAt(last['date']).limit(pageSize)
}
function mergeData({ detail: { data } }) {
  if (!data) return
  if (data.length === 1 && events.length === 0 && data[0].id === $live) return
  events = getUniqueListBy([...events, ...data], 'id').sort(
    (a, b) => b.date.seconds - a.date.seconds,
  )
  last = events[events.length - 1]
}

function getUniqueListBy(arr, key) {
  return [...new Map(arr.map((item) => [item[key], item])).values()]
}

function dateString(date) {
  let string = formatRelative(date.toDate(), new Date(Date.now()), {
    locale: enGB,
  })
  string = string.charAt(0).toUpperCase() + string.slice(1)
  return string
}

function manage(id) {
  live.set(id)
  dispatch('goto', {
    page: '/live/quiz',
  })
}
</script>

<Header title="Events" selected={false} required={false} on:open={newEvent} />

<ul class="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
  {#each events as event}
    <li class="col-span-1 bg-white rounded-lg shadow">
      <div class="flex items-center justify-between w-full p-6 space-x-6">
        <div class="flex-1 truncate">
          <div class="flex items-center space-x-3">
            <h3 class="text-sm font-medium leading-5 text-gray-900 truncate">
              {dateString(event.date)}
            </h3>
            <span
              class="flex-shrink-0 inline-block px-2 py-0.5 text-teal-800 text-xs leading-4
            font-medium bg-teal-100 rounded-full">
              {event.id}
            </span>
          </div>
          <p class="mt-1 text-sm leading-5 text-gray-500 truncate">
            {event.quiz.name}
            at
            {event.venue.name}
          </p>
        </div>
      </div>
      <div class="border-t border-gray-200">
        <div class="flex -mt-px">
          <div class="flex flex-1 w-0">
            <span
              on:click={() => {
                manage(event.id)
              }}
              class="relative inline-flex items-center justify-center flex-1 w-0 py-4 -mr-px text-sm font-medium leading-5 text-gray-700 transition duration-150 ease-in-out border border-transparent rounded-bl-lg cursor-pointer hover:text-gray-500 focus:outline-none focus:shadow-outline-blue focus:border-blue-300 focus:z-10">
              <svg
                class="w-5 h-5 text-gray-400"
                viewBox="0 0 20 20"
                fill="currentColor">
                <path
                  d="M11.3 1.046A1 1 0 0112 2v5h4a1 1 0 01.82 1.573l-7 10A1 1 0 018 18v-5H4a1 1 0
              01-.82-1.573l7-10a1 1 0 011.12-.38z" />
              </svg>
              <span class="ml-3">Manage</span>
            </span>
          </div>
        </div>
      </div>
    </li>
  {/each}
</ul>

<Collection path={`org/${user.org}/events`} query={query} on:data={mergeData}>
  {#if last}
    <div class="flex justify-center mt-8 mb-5">
      <span class="inline-flex rounded-md shadow-sm ">
        <button
          on:click={() => {
            if (last) loadMore(last)
          }}
          type="button"
          class="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50">
          Load More
        </button>
      </span>
    </div>
  {/if}
  <div slot="loading" class="flex justify-center mt-8 mb-5"><span /></div>
</Collection>
