<script>
import { Collection } from 'sveltefire'
import { context } from '../../stores.js'
import Header from '../../components/Header.svelte'

import { createEventDispatcher } from 'svelte'
const dispatch = createEventDispatcher()

export let user
let quizzes = []
let selected = []
$: {
  selected = quizzes.filter((x) => x.selected)
}

function newQuiz() {
  dispatch('goto', {
    page: '/quizzes/new',
  })
}
function useQuiz() {}

async function deleteQuizzes() {
  let toDelete = selected
  selected = []
  const deletions = toDelete.map((quiz) => {
    return quiz.ref.delete()
  })
  await Promise.all(deletions)
  let deletedIDs = toDelete.map((quiz) => quiz.id)
  quizzes = quizzes.filter((q) => !deletedIDs.includes(q.id))
}

let pageSize = 5
let query = (ref) => ref.orderBy('date', 'desc').limit(pageSize)
function loadMore(last) {
  query = (ref) =>
    ref.orderBy('date', 'desc').startAt(last['date']).limit(pageSize)
}
function mergeData({ detail: { data } }) {
  if (data) {
    const unique = data.filter(d => !quizzes.some(x => x.id === d.id))
    quizzes = [...quizzes, ...unique]
  }
}

function getDisplay(item, type) {
  if (type == 'question') return item.question
  if (type == 'song') return `${item.artist} - ${item.title} (${item.year})`
  if (type == 'picture') return `${item.prompt} (${item.answer})`
}
</script>

<Header
  title="Quizzes"
  selected={selected.length}
  required={$context ? $context.num : false}
  on:use={useQuiz}
  on:delete={deleteQuizzes}
  on:open={newQuiz} />

{#each quizzes as quiz}
  <div
    on:click={() => (quiz.selected = !quiz.selected)}
    class="mb-8 overflow-hidden bg-white border-4 shadow sm:rounded-lg
    {quiz.selected ? 'border-purple-200' : 'border-transparent'}">
    <div
      class="px-4 py-5 border-b border-gray-200 sm:px-6
    {quiz.selected ? 'bg-purple-50 italic' : ''}">
      <h3 class="text-lg font-medium leading-6 text-gray-900">{quiz.name}</h3>
    </div>
    <div class="px-4 py-5 sm:p-0
    {quiz.selected ? 'bg-purple-50' : ''}">
      <div class="sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6 sm:py-5">
        {#each quiz.sections as section}
          <div class="sm:col-span-3">{section.name}</div>

          {#each section.rounds as round}
            {#if round.parts.length}
              <div
                class="text-sm font-medium leading-5 text-gray-500 capitalize">
                {#if round.name}{round.name}{/if}
              </div>
              <div class="sm:col-span-2">
                {#each round.parts as part}
                  <div class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
                    {getDisplay(part, round.of)}
                  </div>
                {/each}
              </div>
            {:else}
              <div
                class="text-sm font-medium leading-5 text-gray-500 capitalize">
                {round.of.replace('_', ' ')}
              </div>
              <div class="sm:col-span-2">
                <div class="mt-1 text-sm leading-5 text-gray-900 sm:mt-0">
                  {round.value}
                </div>
              </div>
            {/if}
          {/each}
        {/each}
      </div>
    </div>
  </div>
{/each}

<Collection
  path={`org/${user.org}/quizzes`}
  once={true}
  query={query}
  on:data={mergeData}
  let:last>
  {#if last}
    <div class="flex justify-center mt-8 mb-5">
      <span class="inline-flex rounded-md shadow-sm ">
        <button
          on:click={() => {
            if (last) loadMore(last)
          }}
          type="button"
          class="inline-flex items-center px-4 py-2 text-base font-medium leading-6 text-gray-700 transition duration-150 ease-in-out bg-white border border-gray-300 rounded-md hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:text-gray-800 active:bg-gray-50">
          Load More
        </button>
      </span>
    </div>
  {/if}
  <div slot="loading" class="flex justify-center mt-8 mb-5"><span /></div>
</Collection>
